import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import { mainAxios } from '@/plugins/axios';
class Linea 
{
    id?: number;
    plantaId?: number;
    plantaName?: string;
    descripcion?: string;
    estado?: boolean;

    constructor(_id?:number,_plantaId?:number,_plantaName?: string,_descripcion?: string,_estado?:boolean)
    {
        this.id = _id;
        this.plantaId = _plantaId;
        this.plantaName = _plantaName;
        this.descripcion  =_descripcion;
        this.estado = _estado;
    }
}


async function  GuardarLinea(linea:Linea):Promise<ResponseGenerico>
{
    return await Save<Linea>("linea/Guardar",linea,mainAxios);
} 

async function  EditarLinea(linea:Linea):Promise<ResponseGenerico>
{
    return await Editar<Linea>("linea/Actualizar",linea,mainAxios);
} 

async function  EditarEstadoLinea(linea:Linea):Promise<ResponseGenerico>
{
    return await Editar<Linea>("linea/CambiarEstado",linea,mainAxios);
} 

async function ObtenerLinea()
{
    return await Obtener<Linea>("linea/Obtener",mainAxios);
}


async function ObtenerSelectLinea()
{
    return await Obtener<Linea>("linea/ObtenerSelect",mainAxios);
}


async function ObtenerLineaSelect(plantaId:number)
{
    return Obtener<Linea>(`linea/obtenerselect/${plantaId}`,mainAxios);
}

export {
    Linea,
    GuardarLinea,
    EditarLinea,
    EditarEstadoLinea,
    ObtenerLinea,
    ObtenerSelectLinea,
    ObtenerLineaSelect
}