

































import {Linea,GuardarLinea,EditarLinea} from '@/entidades/Maestro/Ktb/Linea';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {Planta,ObtenerPlantaSelect} from '@/entidades/Maestro/Planta';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
@Component
export default class FormularioAddLinea extends Vue
{

  @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
  @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
  @Prop({required:true,type:Number})
  action!:number;
  @Prop({required:false})
  modelo!:Linea;

  descripcion?:string="";
  lineaId?:number =0;
  plantaId:number =0;

  listadoPlanta:Array<Planta> = [];
  //Configuracion de validacion
  @Validations()
     validations = {
         descripcion : {required},
  }

 async guardar()
 {
     try
     {
         this.changeLoading(new Loading(true,"Registrando informacion"));
         let response = new ResponseGenerico(false,"");
         let linea:Linea = new Linea(this.lineaId,this.plantaId,"", this.descripcion,true);
         response = this.action == 1 ?  await GuardarLinea(linea) : await EditarLinea(linea);
         if(response.isSuccess == true)
         {
            this.$emit('limpiar');
         }
         else
         {
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
         }
     }
     catch(error)
     {
         //@ts-ignore
        this.changeAlerta(new Alerta(error,true,"danger",3000));
     }
     finally
     {
         this.changeLoading(new Loading(false,""));
     }
    
 }


 get mensaje()
 {
     if(this.action == 1)
        return {'titulo' : "REGISTRO DE LINEA" , 'boton' : "GUARDAR"};
    else
        return {'titulo' : "EDICION DE LINEA" , 'boton' : "EDITAR"};
 }

async ObtenerPlanta()
 {
     try
     {
         let rsp = await ObtenerPlantaSelect();
         if(rsp.isSuccess == true)
         {
            this.listadoPlanta = rsp.isResult;
         }
     }
     catch(error)
     {
         console.log(error);
     }
 }

 mounted() {
    if(this.modelo!=undefined)
    {
        this.descripcion = this.modelo.descripcion;
        this.lineaId = this.modelo.id;
    }
    this.ObtenerPlanta();   
 }

}
