









































import {Vue,Component} from 'vue-property-decorator'
import TablaGeneralFinalComponent from '@/components/General/TablaGeneralFinal.vue';
import Formulario from '@/components/Maestro/Linea/Formulario.vue'
import {Linea, EditarEstadoLinea, ObtenerLinea} from '@/entidades/Maestro/Ktb/Linea';
import { Action } from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
@Component({
    components:{
        'Tabla' : TablaGeneralFinalComponent,
        'Formulario' :Formulario
    }
})
export default class LineaView extends Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Planta",sortable:true,value:'plantaName'},
            {text:"Descripcion",sortable:true,value:'descripcion'},
            {text:"Action",sortable:false,value:'actions'},
            {text:"Estado",sortable:true,value:'estado'}          
    ];
    modal:boolean=false;
    currentComponent:string="";
    listado:Array<Linea> = [];
    modelo:Linea |undefined= undefined;
    actions:number =1;
    async editarEstado(linea:Linea)
    {
        try
        {
            this.changeLoading(new Loading(true,"Editando informacion"));
            let rsp = await EditarEstadoLinea(linea);
            if(rsp.isSuccess == true)
            {
                this.limpiar();
            }
            else 
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {
            console.log(error);
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    async abrilModal()
    {
        this.actions = 1;
        this.currentComponent = 'Formulario';
        this.modal= true;
    }
    editarModal(linea:Linea)
    {
        this.currentComponent = "Formulario";
        this.actions = 2;
        this.modelo = linea;
        this.modal = true;
    }

    async Obtener()
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion"));
            let rsp = await ObtenerLinea();
            console.log(rsp);
            if(rsp.isSuccess == true)
            {
                this.listado = rsp.isResult;
            }
        }
        catch(error)
        {
            console.log(error);
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    limpiar()
    {
        this.modelo = undefined;
        this.Obtener();
        this.modal = false;
        this.currentComponent = '';
        
    }

    get getPropiedades()
    {
       if(this.currentComponent == "Formulario")
        {
            return {'action' :this.actions, 'modelo' :this.modelo};
        }
    }


    mounted() 
    {
     this.Obtener();
     
    }

}
